import { NpmIcon } from '@expo/styleguide-icons/custom/NpmIcon';
import { FolderIcon } from '@expo/styleguide-icons/outline/FolderIcon';
import { Speedometer02Icon } from '@expo/styleguide-icons/outline/Speedometer02Icon';
import { Users01Icon } from '@expo/styleguide-icons/outline/Users01Icon';
import { motion } from 'framer-motion';

import { StatsTile } from '~/scenes/HomeScene/components/StatsTile';
import { DotsPattern } from '~/scenes/HomeScene/illustrations/DotsPattern';
import { H2 } from '~/ui/components/text';
import Content from '~/ui/patterns/layouts/Content';

export function StatsSection() {
  return (
    <motion.section
      className="relative bg-screen py-24"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.75 }}>
      <DotsPattern name="statsPattern" className="fill-palette-gray7 dark:fill-palette-gray4" />
      <Content className="!max-w-[775px]">
        <H2 className="mb-12 text-center">An open-source platform built for production apps</H2>
        <div className="grid grid-cols-4 gap-6 max-md-gutters:grid-cols-2 max-md-gutters:gap-y-12">
          <StatsTile
            Icon={Speedometer02Icon}
            title="120 FPS"
            description="Animations"
            iconClassName="text-palette-blue10"
            position={1}
          />
          <StatsTile
            Icon={FolderIcon}
            title="750K+"
            description="Projects"
            iconClassName="text-palette-orange9"
            position={2}
          />
          <StatsTile
            Icon={NpmIcon}
            title="75+"
            description="Premade modules"
            iconClassName="text-palette-red9"
            position={3}
          />
          <StatsTile
            Icon={Users01Icon}
            title=">50%"
            description="React Native projects using Expo"
            iconClassName="text-palette-pink9"
            position={4}
          />
        </div>
      </Content>
    </motion.section>
  );
}
