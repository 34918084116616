import { BrandsSection } from './sections/BrandsSection';
import { DeploySection } from './sections/DeploySection';
import { DevelopSection } from './sections/DevelopSection';
import { FeaturesSection } from './sections/FeaturesSection';
import { HeroSection } from './sections/HeroSection';
import { LinksSection } from './sections/LinksSection';
import { ReviewSection } from './sections/ReviewSection';
import { ServicesSection } from './sections/ServicesSection';
import { SignUpSection } from './sections/SignUpSection';
import { SnacksSection } from './sections/SnacksSection';
import { StatementSection } from './sections/StatementSection';
import { StatsSection } from './sections/Stats';

export function HomeScene() {
  return (
    <div className="backface-hidden transform-gpu">
      <HeroSection />
      <BrandsSection />
      <StatementSection />
      <DevelopSection />
      <ReviewSection />
      <DeploySection />
      <StatsSection />
      <ServicesSection />
      <FeaturesSection />
      <SnacksSection />
      <SignUpSection />
      <LinksSection />
    </div>
  );
}
