import { Button, mergeClasses } from '@expo/styleguide';
import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';
import { motion, useReducedMotion, Variants } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import * as Analytics from '~/common/analytics';
import { FramerHorizontalScroll } from '~/scenes/HomeScene/components/FramerHorizontalScroll';
import { HEADLINE } from '~/ui/components/text';
import { SWIPE_THRESHOLD, swipePower } from '~/ui/foundations/gestures';
import Content from '~/ui/patterns/layouts/Content';
import { useAnimationLooper } from '~/ui/patterns/useAnimationLooper';

type Props = {
  headline?: string;
  className?: string;
};

export function BrandsSection({ className, headline = 'Trusted by top companies' }: Props) {
  const shouldReduceMotion = useReducedMotion();
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const { ref, inView } = useInView();
  const { setPlay } = useAnimationLooper(
    () => setCurrentPage((prevPage) => (prevPage + 1) % BRANDS.length),
    8000,
    !shouldReduceMotion
  );

  useEffect(() => {
    setPlay(!shouldReduceMotion && inView);
  }, [inView]);

  return (
    <section className={mergeClasses('py-20 max-md-gutters:pt-12', className)} ref={ref}>
      <Content>
        <div
          className={mergeClasses(
            'mb-10 flex items-center justify-between',
            'max-md-gutters:flex-col max-md-gutters:items-center max-md-gutters:gap-y-3'
          )}>
          <HEADLINE>{headline}</HEADLINE>
          <Button
            href="/customers"
            theme="secondary"
            rightSlot={<ArrowRightIcon />}
            onClick={() => Analytics.track(Analytics.events.HOMEPAGE_READ_STORIES_CLICKED)}
            className="rounded-full px-5">
            Read Their Stories
          </Button>
        </div>
        <motion.div
          ref={containerRef}
          className="max-lg-gutters:hidden"
          onHoverStart={() => setPlay(false)}
          onHoverEnd={() => setPlay(true)}>
          <motion.div
            className="flex cursor-grab items-center justify-center gap-x-28"
            drag="x"
            dragMomentum={false}
            dragConstraints={containerRef}
            dragElastic={0}
            onDragEnd={(_, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -SWIPE_THRESHOLD) {
                setCurrentPage((prevPage) => (prevPage + 1) % BRANDS.length);
              } else if (swipe > SWIPE_THRESHOLD) {
                setCurrentPage((prevPage) =>
                  prevPage === 0 ? BRANDS.length - 1 : Math.abs(prevPage - 1) % BRANDS.length
                );
              }
            }}>
            {BRANDS[currentPage].map((brand, index) => (
              <motion.div
                key={`${brand}-logo`}
                className="flex min-h-[100px] max-w-[184px] items-center justify-center"
                viewport={{ once: true }}
                variants={getLogosVariants(index)}
                initial="offscreen"
                whileInView="onscreen">
                <img
                  src={`/static/home/logos/${brand}-color.svg`}
                  alt={`${brand}-logo`}
                  className={LOGO_CLASSES}
                />
              </motion.div>
            ))}
          </motion.div>
          <div className="mt-4 flex justify-center gap-1">
            {[...new Array(Math.ceil(BRANDS.length)).keys()].map((page) => (
              <div
                key={`paginator-${page}`}
                onClick={() => setCurrentPage(page)}
                className="group cursor-pointer p-1">
                <div
                  className={mergeClasses(
                    'size-2.5 rounded-full bg-element',
                    'group-hover:bg-hover',
                    page === currentPage && '!bg-palette-gray8'
                  )}
                />
              </div>
            ))}
          </div>
        </motion.div>
        <FramerHorizontalScroll
          className="-mx-6 hidden gap-16 px-6 max-lg-gutters:flex"
          trackClassName="max-lg-gutters:opacity-100 max-lg-gutters:inset-x-32 max-md-gutters:inset-x-16">
          {BRANDS.flat().map((brand) => (
            <div
              key={`${brand}-logo-mobile`}
              className="flex min-h-[90px] min-w-[140px] items-center justify-center">
              <img
                src={`/static/home/logos/${brand}-color.svg`}
                alt={`${brand}-logo`}
                className={LOGO_CLASSES}
              />
            </div>
          ))}
        </FramerHorizontalScroll>
      </Content>
    </section>
  );
}

function getLogosVariants(index: number): Variants {
  return {
    offscreen: {
      translateY: 6,
      opacity: 0,
    },
    onscreen: {
      translateY: 0,
      opacity: 1,
      transition: {
        duration: 0.35,
        delay: index * 0.05,
      },
    },
  };
}

export const LOGO_CLASSES = mergeClasses(
  'backface-hidden pointer-events-none transform-gpu select-none !rounded-none',
  'dark:brightness-[0.35] dark:grayscale dark:invert dark:saturate-[0.15] dark:sepia-[0.01]'
);

const BRANDS = [
  ['better', 'blackline', 'bounce', 'brex'],
  ['cameo', 'codecademy', 'dailypay', 'facepunch'],
  ['few', 'front', 'goody', 'insider', 'lirr'],
  ['pave', 'petal', 'pizza-hut', 'plt', 'prodigy'],
  ['shapeshift', 'spikeball', 'trinet', 'zoe'],
];
