import { Button, LinkBase, mergeClasses } from '@expo/styleguide';
import { StoplightDuotoneIcon } from '@expo/styleguide-icons/custom/StoplightDuotoneIcon';
import { Rocket01DuotoneIcon } from '@expo/styleguide-icons/duotone/Rocket01DuotoneIcon';
import { Stars02DuotoneIcon } from '@expo/styleguide-icons/duotone/Stars02DuotoneIcon';
import { TerminalBrowserDuotoneIcon } from '@expo/styleguide-icons/duotone/TerminalBrowserDuotoneIcon';
import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';
import { ArrowUpRightIcon } from '@expo/styleguide-icons/outline/ArrowUpRightIcon';

import * as Analytics from '~/common/analytics';
import { H1, P } from '~/ui/components/text';
import Content from '~/ui/patterns/layouts/Content';

import { FramerCarousel } from '../components/FramerCarousel';
import { HeroDeployIllustration } from '../illustrations/HeroDeployIllustration';
import { HeroDevelopIllustration } from '../illustrations/HeroDevelopIllustration';

const HEADING_BOX = 'inline-block pb-1.5 px-2 rounded-md !leading-[1.37] max-sm-gutters:pb-1';

export function HeroSection() {
  return (
    <section className="select-none overflow-x-hidden pb-28 pt-32 max-lg-gutters:pb-16 max-lg-gutters:pt-12 max-md-gutters:pb-8">
      <Content
        className={mergeClasses(
          'grid grid-cols-2 gap-2',
          'max-lg-gutters:grid-cols-1 max-lg-gutters:gap-20'
        )}>
        <div>
          <LinkBase
            href="/changelog"
            className={mergeClasses(
              'mb-8 inline-flex items-center rounded-full border border-palette-red6 bg-palette-red2 text-xs text-palette-red10 transition-colors duration-300',
              'hocus:bg-palette-red3'
            )}>
            <div className="flex items-center gap-2 py-1 pl-4 pr-5">
              <Stars02DuotoneIcon className="icon-sm text-palette-red10" />
              Learn what’s new!
              <ArrowRightIcon className="icon-sm text-palette-red10" />
            </div>
          </LinkBase>
          <H1
            className={mergeClasses(
              'mb-6 mt-2 select-text !text-[37px] !font-normal !leading-[59px] !tracking-tight',
              'max-xl-gutters:!text-[32px] max-xl-gutters:!leading-[51px]',
              'max-sm-gutters:!text-[28px] max-sm-gutters:!leading-[45px]'
            )}>
            <span className="relative top-[3px] max-sm-gutters:top-0">Expo and EAS are</span>
            <br />
            an ecosystem of tools that help you <br className="max-sm-gutters:hidden" />
            <span className={mergeClasses(HEADING_BOX, 'bg-palette-blue3 text-palette-blue10')}>
              <TerminalBrowserDuotoneIcon className="inline size-7 text-palette-blue10 max-sm-gutters:icon-lg" />{' '}
              develop
            </span>{' '}
            <span className={mergeClasses(HEADING_BOX, 'bg-palette-orange3 text-palette-orange10')}>
              <StoplightDuotoneIcon className="-mx-0.5 inline size-7 text-palette-orange10 max-sm-gutters:icon-lg" />{' '}
              review
            </span>
            <span className="px-2">&</span>
            <span className={mergeClasses(HEADING_BOX, 'bg-palette-green3 text-palette-green10')}>
              <Rocket01DuotoneIcon className="inline size-7 text-palette-green10 max-sm-gutters:icon-lg" />{' '}
              deploy
            </span>
          </H1>
          <P
            theme="secondary"
            className="mb-14 max-w-[540px] select-text !text-lg max-lg-gutters:mr-0 max-md-gutters:mb-10">
            Create universal native apps with React that run on Android, iOS, and the web. Iterate
            with confidence.
          </P>
          <div className="flex gap-5 max-sm-gutters:flex-col">
            <Button
              href="/signup"
              onClick={() => Analytics.track(Analytics.events.HOMEPAGE_GET_STARTED_CLICKED)}
              size="2xl"
              className={mergeClasses(
                'rounded-[12px] border-0 bg-palette-black font-semibold text-palette-white',
                'dark:bg-palette-white dark:text-palette-black',
                'hocus:bg-palette-black hocus:opacity-80',
                'dark:hocus:bg-palette-white',
                'max-sm-gutters:justify-center'
              )}
              rightSlot={<ArrowRightIcon className="text-palette-white dark:text-palette-black" />}
              skipCapitalization>
              Sign Up for Free
            </Button>
            <Button
              openInNewTab
              href="https://docs.expo.dev"
              onClick={() =>
                Analytics.track(Analytics.events.HOMEPAGE_GET_STARTED_CLICKED_LINKED_TO_DOCS)
              }
              size="2xl"
              theme="secondary"
              className="rounded-[12px] font-semibold max-sm-gutters:justify-center"
              rightSlot={<ArrowUpRightIcon />}>
              Read the Docs
            </Button>
          </div>
        </div>
        <FramerCarousel
          data={[
            {
              content: (
                <>
                  <div className="absolute -top-10 flex h-[460px] w-[380px] max-w-[380px] rounded-3xl bg-palette-blue3" />
                  <HeroDevelopIllustration />
                </>
              ),
              activeColor: 'bg-palette-blue7',
            },
            {
              content: (
                <>
                  <div className="absolute -top-10 flex h-[460px] w-[380px] max-w-[380px] rounded-3xl bg-palette-orange3" />
                  <picture
                    className={mergeClasses(
                      'pointer-events-none absolute left-[5%] top-[10%] max-w-[440px] cursor-grab',
                      'max-xl-gutters:max-w-screen-sm'
                    )}>
                    <img
                      className="hidden dark:block"
                      src="/static/home/review-github-dark.png"
                      alt="Expo workflow"
                      width={1046}
                      height={689}
                      loading="lazy"
                    />
                    <img
                      className="dark:hidden"
                      src="/static/home/review-github.png"
                      alt="Expo workflow"
                      width={1046}
                      height={689}
                      loading="lazy"
                    />
                  </picture>
                  <img
                    className="pointer-events-none absolute right-[12.5%] top-[-4%] z-10 max-h-[420px] cursor-grab asset-shadow"
                    src="/static/home/hero-review.webp"
                    alt="Review"
                  />
                </>
              ),
              activeColor: 'bg-palette-orange7',
            },
            {
              content: (
                <>
                  <div className="absolute -top-10 flex h-[460px] w-[380px] max-w-[380px] rounded-3xl bg-palette-green3" />
                  <HeroDeployIllustration />
                </>
              ),
              activeColor: 'bg-palette-green7',
            },
          ]}
        />
      </Content>
    </section>
  );
}
